'use client'
import FavoriteIcon from '@/app/icons/favorite'
import FavoritesContext, { useToggleFavorites } from '@/context/favorites-manager'
import { FlashMessageContext } from '@/context/flash-message'
import UserContext from '@/context/user-context'
import { cn } from '@/util/cn'
import { IGif } from '@giphy/js-types'
import { getContentOfGif, getCreatorOfGif, setGADataLayer } from 'analytics'
import { ReactNode, useContext, useEffect, useState } from 'react'
import { useLocalStorage } from 'react-use'

type Props = {
    children?: ReactNode
    className?: string
    iconClassName?: string
    iconSize?: number
    gif: IGif
    isFavorite: boolean
}

const Favorites = ({ gif, iconSize, className = '', children, isFavorite, iconClassName }: Props) => {
    const { user } = useContext(UserContext)
    const [hasSeenMessage, setHasSeenMessage] = useLocalStorage('hasSeenLocalFavoritesMessage', false)
    const { showMessage } = useContext(FlashMessageContext)
    const toggleFavorite = useToggleFavorites()
    const { setFavorites } = useContext(FavoritesContext)
    useEffect(() => {
        // update the context so toggleFavorite works
        if (isFavorite) setFavorites((fav) => [...fav, gif.id as string])
    }, [isFavorite, gif.id, setFavorites])
    const [clickedFavorite, setIsClickedFavorite] = useState<boolean | undefined>(undefined)
    const isActualFavorite = clickedFavorite === undefined ? isFavorite : clickedFavorite
    return (
        <div
            className={className}
            onClick={async (e) => {
                e.preventDefault()
                e.stopPropagation()
                // manage local state now
                setIsClickedFavorite(!isActualFavorite)
                const isFavorite = await toggleFavorite(gif.id as string)
                if (isFavorite) {
                    try {
                        setGADataLayer({
                            event: 'favorite_success',
                            options: {
                                content: getContentOfGif(gif),
                                creator: { ...getCreatorOfGif(gif), user_is_creator: user?.id === gif.user?.id },
                            },
                        })
                    } catch (error) {}
                }
                if (!user && !hasSeenMessage) {
                    setHasSeenMessage(true)
                    showMessage({ message: 'Favorite saved! You can access your favorites from the user menu.' })
                }
            }}
        >
            <FavoriteIcon
                width={iconSize}
                height={iconSize}
                // override the utility for all icons if favorited
                className={cn(iconClassName, isActualFavorite && '!fill-giphyRed')}
            />
            {children}
        </div>
    )
}
export default Favorites

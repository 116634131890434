import { Interface, NexaBlack } from '@/styles/fonts'
import { getCookie } from '@/util/url'
import { CSRF_COOKIE_NAME } from '@/util/user'
import * as colors from '@giphy/colors'
import { useContext, useEffect, useMemo, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import Button from 'ui/src/components/controls/button'
import DropdownButton_ from 'ui/src/components/controls/dropdown-button'
import SelectList from 'ui/src/components/controls/select-list'
import UserContext from '@/context/user-context'
import { timeoutFetch } from 'utils/src/api/fetch'

export const ColoredStrip = styled.div`
    background: linear-gradient(240deg, #f66 7.79%, #e646b6 92.21%);
    width: 100%;
    height: 5px;
    position: absolute;
    top: 0;
    left: 0;
`

const DropdownButton = styled(DropdownButton_)`
    height: 42px;
    margin-top: 10px;
    margin-bottom: 10px;

    button {
        height: 42px;
    }
`

const Checkbox = styled.div<{ checked: boolean }>`
    background: ${colors.giphyDarkGrey};
    border-radius: 3px;
    height: 24px;
    width: 24px;
    margin: 0 7px;
    cursor: pointer;

    border-radius: 4px;
    border: 2px solid ${(props) => (props.checked ? '#0f9' : '#5c5c5c')};
    background: #121212;

    &:before {
        content: '';
        width: 20px;
        height: 20px;
        position: absolute;
        filter: grayscale(1);
        ${(props) =>
            props.checked &&
            css`
                background-image: url('/static/img/check-mark.png');
                background-size: 64%;
                background-repeat: no-repeat;
                background-position: center;
            `}
    }
`

const CheckboxLabel = styled.span<{ checked: boolean }>`
    color: ${(props) => (props.checked ? '#FFF' : '#616060')};

    font-family: ${Interface.style.fontFamily};
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px;
`

const CancelButton = styled.p`
    color: var(--Grey-07-Brooklyn-Snow, #a6a6a6);
    text-align: center;
    font-weight: normal;
    letter-spacing: 0.3px;
    font-size: 14px;
    line-height: 18px;
    margin-top: 20px;

    font-family: ${Interface.style.fontFamily};
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    cursor: pointer;
`
const SubmitButton = styled(Button)<{ disabled: boolean }>`
    width: 100%;
    font-size: 16px;
    line-height: 48px;
    height: 42px;
    border-radius: 2px;
    margin-top: 42px;
    ${({ disabled }) => !disabled && 'background: linear-gradient(45deg, rgb(0, 204, 255) 0%, rgb(97, 87, 255) 100%);'}
    &:hover {
        filter: brightness(1.1);
    }
`

const ReportModalContainer = styled.div`
    background-color: rgba(0, 0, 0, 0.7);
    &::-webkit-scrollbar {
        display: none;
    }
`
const ReportDialogForm = styled.form`
    position: absolute;
    top: 60px;
    left: 50%;
    width: 100%;
    height: auto;
    padding: 40px 20px;
    background-color: green;
    transform: translateX(-50%);
    z-index: 999;
    background: ${colors.giphyBlack};
    transition: all 1s ease-out;
    max-width: 600px;
`

const FieldRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
`

const FieldContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    &:nth-child(2) {
        margin-left: 30px;
    }
`
const DropdownFieldContainer = styled(FieldContainer)<{ fixedWidth?: number }>`
    ${({ fixedWidth }) => fixedWidth && 'width:' + fixedWidth + 'px;'}

    & > div > div:first-child > div {
        background-color: #2e2e2e;
        height: 42px;
        width: 42px;
    }

    & > div > div {
        background-color: #212121;
    }
`

export const FieldExplanation = styled.span`
    color: ${colors.giphyLightGrey};
    font-size: 16px;
    align-items: center;
`

const RowLabelWithAsterisk = styled.div<{ displayAsterisk: boolean }>`
    color: white;
    font-size: 15px;
    font-weight: bold;
    justify-content: flex-start;

    div {
        flex-direction: row;
    }
    span {
        display: flex;
    }
    &::after {
        content: ' *';
        color: var(--color-grey-5-greg, #4e4e4e);
        font-family: ${Interface.style.fontFamily};
        font-size: 15px;
        font-style: normal;
        font-weight: 700;
        line-height: 22px;
        display: ${({ displayAsterisk }) => (displayAsterisk ? 'inline' : 'none')};
    }
`

const MessagingHeader = styled.div`
    display: flex;
    flex-direction: row;
    color: ${colors.giphyLightestGrey};
    font-family: ${Interface.style.fontFamily};
    font-size: 17px;
    font-weight: bold;
    margin-bottom: 40px;
`

const HeadingText = styled.span`
    color: white;
    font-family: ${NexaBlack.style.fontFamily};
    font-size: 34px;
    line-height: 42px;
    margin-top: 3px;
    font-weight: normal;
    display: table;
    margin: 0 auto;
`

const DarkFields = styled.input`
    width: 100%;
    height: 42px;
    font-size: 16px;
    line-height: 24px;
    font-weight: normal;
    color: ${colors.giphyLightGrey};
    background-color: ${colors.giphyDarkestGrey};
    border-radius: 4px;
    padding: 10px;
    margin-top: 10px;
    resize: none;
    border: none;
    &:focus {
        outline: none;
    }

    &::placeholder {
        color: ${colors.giphyLightGrey};
    }
`

const StyledTextArea = styled.textarea`
    width: 100%;
    height: 96px;
    font-size: 16px;
    line-height: 24px;
    font-weight: normal;
    margin-top: 10px;
    color: white;
    background-color: ${colors.giphyDarkestGrey};
    border-radius: 4px;
    padding: 10px;
    resize: none;
    border: none;
    &:focus {
        outline: none;
    }

    &::placeholder {
        color: ${colors.giphyLightGrey};
    }
`

const CheckboxContainer = styled.div`
    gap: 15px;
    width: 300px;
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-right: 5px;
`

const CheckboxTemp = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`

const OrganizationSection = styled.div<{ isExpand: boolean }>`
    height: ${(props) => (props.isExpand ? 'auto' : '0')};
    opacity: ${(props) => (props.isExpand ? '1' : '0')};
    overflow: hidden;
    transition: all 1s ease-out;
`

const DropdownMenu = styled.div`
    padding: 16px 24px;
    height: 240px;
    overflow-x: auto;
`

// success

const Container = styled.div`
    position: fixed;
    top: 30%;
    left: 50%;
    width: 95%;
    height: 390px;
    transform: translateX(-50%);
    z-index: 999;
    background: rgb(18, 18, 18);
    overflow-y: scroll;
    &::-webkit-scrollbar {
        display: none;
    }
`

export const CloseButton = styled.span`
    width: 18px;
    height: 18px;
    position: absolute;
    top: 25px;
    right: 25px;
    background-size: 100%;
    cursor: pointer;
    background-image: url('/static/img/thick-close-btn.png');
`

const Inner = styled.div`
    text-align: center;
    margin: auto;
    height: 260px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 40px 40px 20px;

    h4 {
        color: var(--color-grey-9-white, #fff);

        text-align: center;
        font-family: ${NexaBlack.style.fontFamily};
        font-size: 28px;
        font-style: normal;
        font-weight: 900;
        line-height: 130%;
    }

    p {
        color: var(--color-grey-8-almost-white, #d8d8d8);
        text-align: center;

        font-family: ${Interface.style.fontFamily};
        font-size: 17px;
        font-style: normal;
        font-weight: 700;
        line-height: 25px;
    }
`

const Footer = styled.div`
    background-color: #212121;
    height: 70px;
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px 20px;

    p {
        width: 485px;
        text-align: center;
        color: var(--color-symantic-paragraph, #a6a6a6);
        text-align: center;
        font-family: ${Interface.style.fontFamily};
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    b {
        font-weight: 700;
    }
`

type Props = {
    className?: string
    toggleForm: () => void
    gif: string
    platform: string
    scrollY: number
}

const CheckboxOption = ({ label, isChecked, onClick }: { label: string; isChecked: boolean; onClick: () => void }) => (
    <CheckboxTemp>
        <Checkbox checked={isChecked} onClick={onClick} />
        <CheckboxLabel checked={isChecked}>{label}</CheckboxLabel>
    </CheckboxTemp>
)

const ANONYMUS_REASONS = ['grooming', 'csam']
const ILLEGAL_REASONS = ['csam', 'grooming', 'terrorism', 'sale', 'activity']

const ReportSubmitted = ({ toggleForm, reason }: { toggleForm: () => void; reason: string | null }) => {
    const DefaultCopy = () => (
        <p>
            GIPHY&apos;s moderation team will review your report and take appropriate action. This typically takes 24
            hours.
        </p>
    )
    const IllegalCopy = () => (
        <p>
            Thank you! If you have provided an email address, you will receive an email to inform you of the action
            we&apos;ve taken in response to your report once it has been reviewed. This typically takes 24 hours.
        </p>
    )
    const isIllegal = useMemo(() => ILLEGAL_REASONS.includes(reason || ''), [reason])
    return (
        <Container>
            <ColoredStrip />
            <CloseButton onClick={toggleForm}></CloseButton>
            <Inner>
                <h4>Thank You!</h4>
                {isIllegal ? <IllegalCopy /> : <DefaultCopy />}
            </Inner>
            <Footer>
                <p>
                    If you would like to report another piece of content, please select the `<b>Report this GIF</b>`
                    button that appears next to the content in question.
                </p>
            </Footer>
        </Container>
    )
}

const ReportModal = ({ toggleForm, gif, platform, className, scrollY }: Props) => {
    const formRef = useRef<HTMLFormElement | null>(null)
    const dropdownRef = useRef<HTMLDivElement | null>(null)
    const user = useContext(UserContext)
    const [formSubmitted, setFormSubmitted] = useState(false)
    const [isOrgatisation, setIsOrgatisation] = useState<boolean | null>(null)
    const [isRelief, setIsRelief] = useState<boolean | null>(null)
    const [selectedReason, setSelectedReason] = useState<string | null>(null)
    const [readyForSubmit, setReadyForSubmit] = useState<boolean>(false)
    const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false)

    const [reportReasons, setReportReasons] = useState<any>([])

    const [nameValid, setNameValid] = useState<boolean>(!!user?.user?.display_name)
    const [emailValid, setEmailValid] = useState<boolean>(!!user?.user?.email)
    const [reasonValid, setReasonValid] = useState<boolean>(false)
    const [isOrgValid, setOrgValid] = useState<boolean>(false)

    const isAnonymus = useMemo(() => ANONYMUS_REASONS.includes(selectedReason as string), [selectedReason])

    const handleFieldValidation = (event: any, setter: (arg0: boolean) => void) => {
        setter(event.target!.value !== '')
    }

    useEffect(() => {
        const isOrganizationValid = isOrgatisation !== null && (isOrgatisation ? isOrgValid : true)
        const isFormValid =
            nameValid && emailValid && reasonValid && selectedReason && isOrganizationValid && isRelief === true

        setReadyForSubmit(!!(isAnonymus && isRelief) || !!isFormValid)
    }, [nameValid, emailValid, isOrgatisation, selectedReason, isAnonymus, isOrgValid, isRelief, reasonValid])

    useEffect(() => {
        setEmailValid(!!user?.user?.email)
    }, [user?.user?.email])

    useEffect(() => {
        setNameValid(!!user?.user?.display_name)
    }, [user?.user?.display_name])

    useEffect(() => {
        setIsDropdownOpen(false)
    }, [scrollY])
    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setIsDropdownOpen(false)
            }
        }
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [dropdownRef, toggleForm])

    const submitForm = async (event: any) => {
        let payload: any = {}
        const csrftoken = getCookie(CSRF_COOKIE_NAME)
        event.stopPropagation()
        event.preventDefault()
        if (formRef.current == null) {
            return
        }
        const formData = new FormData(formRef.current)
        formData.append('reporter_reason', selectedReason as string)
        formData.append('platform', platform)
        formData.append('gif_id', gif)
        formData.forEach((value, key) => (payload[key] = value))

        const response = await timeoutFetch({
            url: `/api/v3/reports/`,
            options: {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': csrftoken,
                },
                body: JSON.stringify(payload),
            },
        })
        if (response.ok) {
            setFormSubmitted(true)
        }
    }

    useEffect(() => {
        const getOptions = async () => {
            const response = await timeoutFetch({
                url: `/api/v3/reports/`,
                options: {
                    method: 'OPTIONS',
                },
            })
            const data = await response.json()
            setReportReasons(data.actions.POST.reporter_reason.choices)
        }
        getOptions()
    }, [])

    const RowLabel = (props: any) => (
        <RowLabelWithAsterisk displayAsterisk={!isAnonymus}>{props.children}</RowLabelWithAsterisk>
    )

    return (
        <>
            <ReportModalContainer>
                {!formSubmitted ? (
                    <ReportDialogForm method="POST" ref={formRef} className={className}>
                        <ColoredStrip />
                        <MessagingHeader>
                            <HeadingText>Report this Content</HeadingText>
                        </MessagingHeader>
                        <DropdownFieldContainer ref={dropdownRef}>
                            <RowLabel>Reason for reporting this content</RowLabel>
                            <DropdownButton
                                isOpen={isDropdownOpen}
                                onToggle={setIsDropdownOpen}
                                label={
                                    reportReasons.find((r: any) => r.value == selectedReason)?.display_name ||
                                    'Must select an option'
                                }
                            >
                                <DropdownMenu ref={dropdownRef}>
                                    <SelectList
                                        onChange={(index) => {
                                            setSelectedReason(reportReasons[index].value)
                                            setIsDropdownOpen(false)
                                        }}
                                        options={reportReasons.map((reason: any) => reason.display_name)}
                                    />
                                </DropdownMenu>
                            </DropdownButton>
                        </DropdownFieldContainer>
                        <FieldRow>
                            <FieldContainer>
                                <RowLabel>Name</RowLabel>
                                <DarkFields
                                    placeholder="Enter Name"
                                    name="reporter_name"
                                    required
                                    type="email"
                                    defaultValue={user?.user?.display_name}
                                    onKeyUp={(e) => handleFieldValidation(e, setNameValid)}
                                    onInput={(e) => handleFieldValidation(e, setNameValid)}
                                />
                            </FieldContainer>
                        </FieldRow>
                        <FieldRow>
                            <FieldContainer>
                                <RowLabel>Email</RowLabel>
                                <DarkFields
                                    placeholder="Enter email"
                                    name="reporter_email"
                                    required
                                    type="email"
                                    defaultValue={user?.user?.email}
                                    onKeyUp={(e) => handleFieldValidation(e, setEmailValid)}
                                    onInput={(e) => handleFieldValidation(e, setEmailValid)}
                                />
                            </FieldContainer>
                        </FieldRow>

                        <FieldRow>
                            <FieldContainer>
                                <RowLabel>Please explain why you believe this content should be removed</RowLabel>
                                <StyledTextArea
                                    placeholder="Enter details"
                                    name="reporter_comment"
                                    onKeyUp={(e) => handleFieldValidation(e, setReasonValid)}
                                    onInput={(e) => handleFieldValidation(e, setReasonValid)}
                                />
                            </FieldContainer>
                        </FieldRow>
                        <FieldRow>
                            <RowLabel>
                                Are you contacting us from an organization designated as a Trusted Flagger by the
                                Digital Services Coordinator?
                            </RowLabel>
                            <CheckboxContainer>
                                <CheckboxOption
                                    label="Yes"
                                    isChecked={isOrgatisation === true}
                                    onClick={() => setIsOrgatisation(true)}
                                />
                                <CheckboxOption
                                    label="No"
                                    isChecked={isOrgatisation === false}
                                    onClick={() => setIsOrgatisation(false)}
                                />
                            </CheckboxContainer>
                        </FieldRow>
                        <OrganizationSection isExpand={isOrgatisation || false}>
                            <FieldRow>
                                <FieldContainer>
                                    <RowLabel>What is the name of your organization?</RowLabel>
                                    <DarkFields
                                        placeholder="Enter Organization Name"
                                        name="trusted_flagger_org"
                                        required
                                        onKeyUp={(e) => handleFieldValidation(e, setOrgValid)}
                                        onInput={(e) => handleFieldValidation(e, setOrgValid)}
                                    />
                                </FieldContainer>
                            </FieldRow>
                        </OrganizationSection>
                        <FieldRow>
                            <RowLabelWithAsterisk displayAsterisk={true}>
                                Do you confirm that you have a bona fide belief that the allegations in this report are
                                accurate?
                            </RowLabelWithAsterisk>
                            <CheckboxContainer>
                                <CheckboxOption
                                    label="Yes"
                                    isChecked={isRelief === true}
                                    onClick={() => setIsRelief(true)}
                                />
                                <CheckboxOption
                                    label="No"
                                    isChecked={isRelief === false}
                                    onClick={() => setIsRelief(false)}
                                />
                            </CheckboxContainer>
                        </FieldRow>

                        <SubmitButton disabled={!readyForSubmit} onClick={submitForm}>
                            Submit
                        </SubmitButton>
                        <CancelButton onClick={toggleForm}>Cancel</CancelButton>
                    </ReportDialogForm>
                ) : (
                    <ReportSubmitted toggleForm={toggleForm} reason={selectedReason} />
                )}
            </ReportModalContainer>
        </>
    )
}

export default ReportModal

import { isiOS } from 'utils'

export function twitter(shareUrl: string) {
    window.open('http://twitter.com/share?url=' + encodeURIComponent(shareUrl || location.href) + '?tc=1&via=giphy')
}
export function facebook(shareUrl: string) {
    window.open('http://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(shareUrl || location.href))
}
export function pinterest(mediaUrl: string, shareUrl: string) {
    window.open(
        'http://pinterest.com/pin/create/bookmarklet/?media=' +
            mediaUrl +
            '&url=' +
            encodeURIComponent(shareUrl || location.href)
    )
}
export function reddit(shareUrl: string) {
    window.open(`//www.reddit.com/submit?url=${encodeURIComponent(shareUrl || location.href)}`)
}
export function sms(url: string) {
    location.href = `sms:${isiOS() ? '&' : '?'}body=${encodeURIComponent(url)}`
}

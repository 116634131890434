'use client'
import { iconClassName } from '@/app/components/detail/menu-icon-css'
import Favorites from '@/components/favorite-button'
import FavoritesContext from '@/context/favorites-manager'
import { cn } from '@/util/cn'
import { IGif } from '@giphy/js-types'
import { ReactNode, useContext } from 'react'

const LocalFavorite = ({
    gif,
    children,
    className,
    iconSize,
}: {
    gif: IGif
    children?: ReactNode
    className?: string
    iconSize?: number
}) => {
    const isFavorite = useContext(FavoritesContext).favorites.includes(gif.id as string)
    return (
        <Favorites
            gif={gif}
            iconSize={iconSize}
            isFavorite={isFavorite}
            className={cn('group', className)}
            iconClassName={iconClassName}
        >
            {children}
        </Favorites>
    )
}

export default LocalFavorite

'use client'
import { NexaBlack } from '@/styles/fonts'
import { giphyLightGrey } from '@giphy/colors'
import { IGif, IImage } from '@giphy/js-types'
import download from 'downloadjs'
import { useContext, useState } from 'react'
import sanitize from 'sanitize-filename'
import Button from 'ui/src/components/controls/button'
import { CloseIcon } from 'ui/src/icons/utility'
import { timeoutFetch } from 'utils/src/api/fetch'
import { ShareUrlRenditionType, ShareUrlRenditions } from 'utils/src/media/sharing'
import { GifPanelContext } from '../../../../../components/detail/gif-panel-context-manager'
import { RenditionTray } from './rendition-tray'

type Props = { gif: IGif }

export const getShareUrl = (url: string) => url.replace(/media\d+\.giphy\.com/, 'media.giphy.com')

const DownloadPanel = ({ gif }: Props) => {
    const [currentRendition, setCurrentRendition] = useState<ShareUrlRenditionType>(ShareUrlRenditions.Social)
    const { setPanel, panel } = useContext(GifPanelContext)
    const mediaType = gif.is_sticker ? 'Sticker' : 'Gif'
    const downloadGif = async (gif: IGif, rendition: ShareUrlRenditionType, isMP4?: boolean) => {
        const url = getShareUrl(
            isMP4 ? (gif.images[rendition] as { mp4: string }).mp4 : (gif.images[rendition] as IImage).url
        )
        const imageUrl = url.replace('media.giphy.com', 'i.giphy.com')
        const result = await timeoutFetch({ url: imageUrl, timeout: 10000 })
        const blob = await result.blob()
        const extension = isMP4 ? 'mp4' : 'gif'
        // http://danml.com/download.html
        download(blob, sanitize(`${gif.title}-${rendition}.${extension}`))
    }
    return panel === 'download' ? (
        <div className="bg-giphyBlack absolute inset-0 flex flex-col bg-opacity-90 p-2">
            <div className="border-giphyWhite flex  justify-between border-b border-solid py-1 font-bold">
                <h4 className={`text-xl ${NexaBlack.className}`}>Download {mediaType}</h4>
                <div onClick={() => setPanel('none')}>
                    <CloseIcon height={18} color={giphyLightGrey} className="cursor-pointer" />
                </div>
            </div>
            <hr />
            <section className="flex flex-1 flex-col justify-center gap-4 px-2">
                <Button
                    gradient="purple-indigo"
                    size="medium"
                    className=""
                    onClick={() => {
                        downloadGif(gif, currentRendition)
                    }}
                >
                    Download GIF
                </Button>
                <Button
                    gradient="purple-cyan"
                    size="medium"
                    onClick={() => downloadGif(gif, ShareUrlRenditions.MP4, true)}
                >
                    Download MP4
                </Button>
            </section>
            <section>
                <RenditionTray currentRendition={currentRendition} setRenditionType={setCurrentRendition} gif={gif} />
            </section>
        </div>
    ) : null
}

export default DownloadPanel

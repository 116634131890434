'use client'

import { publicRuntimeConfig } from '@/app/util/env'
import { setGADataLayer } from 'analytics'
import { ReactNode, useEffect, useState } from 'react'
import AdsContext from './ads'

type Props = { children: ReactNode; adsEnabled: boolean; adUnits: string[]; pageName?: string }
const AdsContextManager = ({ children, adsEnabled: pageAdsEnabled, adUnits, pageName }: Props) => {
    const [adsLoaded, setAdsLoaded] = useState<boolean>(false)
    const [adsEnabled] = useState(
        publicRuntimeConfig.adsEnabled === true && // global setting
            pageAdsEnabled === true // page-level setting
    )
    const isAdUnitEnabled = (unitName: string) => {
        if (!adsEnabled) return false
        return adUnits.includes(unitName)
    }

    useEffect(() => {
        if (!adsLoaded && adsEnabled) {
            window.didomiOnReady = window.didomiOnReady || []
            window.didomiOnReady.push(() => {
                // Triggers "HTL CSS & JS" event in GTM
                setGADataLayer({ event: 'activate_ads' })
                setAdsLoaded(true)
            })
        }
    }, [])

    return (
        <AdsContext.Provider value={{ adsEnabled, adUnits, isAdUnitEnabled, pageName }}>{children}</AdsContext.Provider>
    )
}

export default AdsContextManager

'use client'
import { NexaBlack } from '@/styles/fonts'
import { giphyWhite } from '@giphy/colors'
import { useContext } from 'react'
import CornerCutout from 'ui/src/components/corner-cutout'
import { CloseIcon } from 'ui/src/icons/utility'
import { GifPanelContext } from '../../../../../components/detail/gif-panel-context-manager'

function PanelHeader({ title }: { title: string }) {
    const { setPanel } = useContext(GifPanelContext)
    return (
        <div className="flex flex-col">
            <div className="flex gap-2 pl-2 ">
                <div className="border-giphyWhite mx-2 my-2.5 flex flex-1 justify-between border-b border-solid py-1 font-bold">
                    <h4 className={`text-giphyWhite text-xl ${NexaBlack.className}`}>{title}</h4>
                    <div onClick={() => setPanel('none')}>
                        <CloseIcon height={18} color={giphyWhite} className="cursor-pointer" />
                    </div>
                </div>
                <CornerCutout isVisible className="bg-giphyBlack h-[48px] w-[48px]" />
            </div>
            <hr />
        </div>
    )
}

export default PanelHeader

'use client'
import { SectionHeader } from '@/components/sections'
import { GifsResult } from '@giphy/js-fetch-api'
import { GifID, IGif } from '@giphy/js-types'
import { getGifWidth } from '@giphy/js-util'
import { Carousel, VideoOverlay } from '@giphy/react-components'
import { useState } from 'react'

type Props = {
    label: string
    initialGifs: IGif[]
    fetchGifs: (offset: number) => Promise<GifsResult>
}
// can't use VideoCarousel directly in a server component, the fetchGifs prop requires serialization
const ScrollCarousel = ({ initialGifs, fetchGifs, label }: Props) => {
    const [videoId, setVideoId] = useState<GifID>()
    return (
        <section>
            <SectionHeader label={label} />
            <Carousel
                initialGifs={initialGifs}
                gifHeight={90}
                overlay={(props) => (
                    <VideoOverlay
                        {...props}
                        isHovered={props.gif.id === videoId}
                        height={90}
                        width={getGifWidth(props.gif, 90)}
                        onUserMuted={() => {
                            setVideoId(props.gif.id)
                        }}
                    />
                )}
                fetchGifs={fetchGifs}
            />
        </section>
    )
}
export default ScrollCarousel

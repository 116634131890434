'use client'

import { fetchChannelClips } from '@/app/util/channel-api'
import { useGiphyFetch } from '@/app/util/use-gf'
import UAParserContext from '@/context/ua-parser'
import { GifsResult } from '@giphy/js-fetch-api'
import { IGif } from '@giphy/js-types'
import { useContext } from 'react'
import InfinitePageCarousel from './pagination-carousel'
import ScrollCarousel from './scroll-carousel'

function CarouselSelector({
    initialGifs,
    fetchGifs,
    label,
}: {
    initialGifs: IGif[]
    fetchGifs: (offset: number) => Promise<GifsResult>
    label: string
}) {
    const { deviceType } = useContext(UAParserContext)
    return deviceType === 'desktop' ? (
        <InfinitePageCarousel initialGifs={initialGifs} label={label} fetchGifs={fetchGifs} />
    ) : (
        <ScrollCarousel initialGifs={initialGifs} fetchGifs={fetchGifs} label={label} />
    )
}

type Props = {
    label: string
    initialGifs: IGif[]
}
export const RelatedVideoCarousel = ({ id, initialGifs, label }: Props & { id: string }) => {
    const gf = useGiphyFetch()
    const fetchGifs = (offset: number) => gf.related(id, { offset, type: 'videos', limit: 4 })
    return <CarouselSelector initialGifs={initialGifs} fetchGifs={fetchGifs} label={label} />
}

export const UserClipsVideoCarousel = ({ username, initialGifs, label }: Props & { username: string }) => {
    const fetchGifs = (offset: number) => fetchChannelClips(username, { offset, limit: 4 })
    return <CarouselSelector initialGifs={initialGifs} fetchGifs={fetchGifs} label={label} />
}

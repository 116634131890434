'use client'
import Grid from '@/components/grid/grid'
import { publicRuntimeConfig } from '@/app/util/env'
import { GiphyFetch } from '@giphy/js-fetch-api'
const giphyFetch = new GiphyFetch(publicRuntimeConfig.fourOhFourApiKey)
type Props = {}

const ClientGrid = ({}: Props) => {
    return <Grid fetchGifs={(offset: number) => giphyFetch.trending({ offset })} />
}
export default ClientGrid

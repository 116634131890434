'use client'

import { useContext } from 'react'
import styled from 'styled-components'
import { modalZIndex } from 'ui/src/constants'
import UAParserContext from '@/context/ua-parser'
import AdsContext from '@/context/ads'

export const DESKTOP_CONTAINER_HEIGHT = 300
export const DESKTOP_AD_DIMENSIONS = [300, 250]
export const UNIT_NAME = 'medium_rectangle'

const AdContainer = styled.div`
    border-radius: 4px;
    overflow: hidden;
    display: flex;
    position: fixed;
    bottom: 10px;
    right: 10px;
    z-index: ${modalZIndex - 1};
`

const MediumRectangleAd = () => {
    const { deviceType } = useContext(UAParserContext)
    const { isAdUnitEnabled, pageName } = useContext(AdsContext)
    const className = `htlad-desktop_${UNIT_NAME}_${pageName}`
    const isEnabled = deviceType === 'desktop' && isAdUnitEnabled(UNIT_NAME)

    return (
        isEnabled && (
            <AdContainer>
                <div className={className}></div>
            </AdContainer>
        )
    )
}

export default MediumRectangleAd

'use client'
import NavigateLeftIcon from '@/app/icons/navigate-left'
import NavigateRightIcon from '@/app/icons/navigate-right'
import { cn } from '@/util/cn'
import { Children, ReactNode, useEffect, useState } from 'react'

type Props = {
    title?: ReactNode
    children: ReactNode
    onChange?: (index: number) => void
    maxBlocks?: number
    startIndex?: number
    className?: string
    onDone?: () => void
}

const PaginationCarousel = ({
    title = '',
    children,
    className,
    maxBlocks = 4,
    startIndex = 0,
    onChange,
    onDone,
}: Props) => {
    const [index, setIndex] = useState(startIndex)
    const [showAnimation, setShowAnimation] = useState(false)
    const buttonClassName = 'bg-giphyLightCharcoal cursor-pointer px-2.5 py-1'
    const numChildren = Children.count(children)
    const isDone = startIndex + maxBlocks >= numChildren
    useEffect(() => {
        if (isDone) {
            onDone?.()
        }
    }, [isDone, onDone])
    return (
        <div className={cn('flex flex-col gap-1', className)}>
            <div className="flex justify-between">
                <div>{title}</div>
                <div className="flex gap-1">
                    <button
                        className={cn(buttonClassName, index === 0 && 'pointer-events-none opacity-50')}
                        onClick={() => {
                            const nextIndex = index - maxBlocks
                            setIndex(nextIndex)
                            onChange?.(nextIndex)
                            setShowAnimation(true)
                        }}
                    >
                        <NavigateLeftIcon className="size-3" />
                    </button>
                    <button
                        className={cn(
                            buttonClassName,
                            index >= numChildren - maxBlocks && 'pointer-events-none opacity-50'
                        )}
                        onClick={() => {
                            if (index < numChildren - maxBlocks) {
                                const nextIndex = index + maxBlocks
                                setIndex(nextIndex)
                                onChange?.(nextIndex)
                                setShowAnimation(true)
                            }
                        }}
                    >
                        <NavigateRightIcon className="size-3" />
                    </button>
                </div>
            </div>
            <div className={'flex justify-between'} key={`${index}`}>
                {Children.toArray(children)
                    .slice(index, index + maxBlocks)
                    .map((item, index) => (
                        <div
                            key={index}
                            className={cn(showAnimation && 'animate-slide-up')}
                            style={{
                                animationDelay: `calc(${index + 1} * 0.05s)`,
                                animationTimingFunction: `cubic-bezier(0.77, 0, 0.175, 1)`,
                            }}
                            onAnimationEnd={() => {
                                if (index % maxBlocks === 0) {
                                    setShowAnimation(false)
                                }
                            }}
                        >
                            {item}
                        </div>
                    ))}
            </div>
        </div>
    )
}

export default PaginationCarousel

'use client'
import { IGif } from '@giphy/js-types'
import { getGifHeight, getGifWidth } from '@giphy/js-util'
import { useContext, useState } from 'react'
import Button from 'ui/src/components/controls/button'
import Toggle from 'ui/src/components/controls/toggle'
import { GifPanelContext } from '../../../../../components/detail/gif-panel-context-manager'
import PanelHeader from './panel-header'

type Props = { gif: IGif }

const EmbedPanel = ({ gif }: Props) => {
    const { panel } = useContext(GifPanelContext)
    const mediaType = gif.is_sticker ? 'Sticker' : 'Gif'
    const [isResponsive, setIsResponsive] = useState(false)
    const embedCode = getEmbedCode(gif, isResponsive)
    return panel === 'embed' ? (
        <div className="bg-giphyBlack absolute inset-0 flex flex-col bg-opacity-90">
            <PanelHeader title={`Embed ${mediaType}`} />
            <div className="flex h-full flex-1 flex-col justify-center gap-4 p-5">
                <p>
                    Want to embed this {mediaType} on your website or blog? Just drop in the embed code below and
                    you&apos;re done!
                </p>
                <section>
                    <h4>Responsive</h4>
                    <Toggle
                        gradientOff="purple-cyan"
                        gradientOn="blue-green"
                        labelOff="Off"
                        labelOn="On"
                        style={{ width: 200 }}
                        onChange={(isOn: boolean) => {
                            setIsResponsive(isOn)
                        }}
                    />
                </section>
                <section>
                    <h4>Embed Code</h4>
                    <div className="flex items-center">
                        <input type="text" className="h-10 flex-1 rounded-l p-1" value={embedCode} readOnly />
                        <Button
                            gradient="purple-indigo"
                            size="medium"
                            className="rounded-none"
                            style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                            onClick={() => {
                                // doesn't work on http, but does work on https
                                navigator.clipboard?.writeText(embedCode)
                            }}
                        >
                            Copy Code
                        </Button>
                    </div>
                </section>
            </div>
        </div>
    ) : null
}

export default EmbedPanel

const EMBED_SIZE = 480

export const getEmbedCode = (gif: IGif, isResponsive = false) => {
    const {
        url,
        images: { original },
    } = gif
    let width = EMBED_SIZE
    let height = getGifHeight(gif, width)
    if (original.width < original.height) {
        height = EMBED_SIZE
        width = getGifWidth(gif, height)
    }
    const ratio = height / width
    const params = {
        src: gif.embed_url,
        width: isResponsive ? '100%' : Math.round(width),
        height: isResponsive ? '100%' : Math.round(height),
        style: isResponsive ? 'position:absolute' : '',
        frameBorder: '0',
        class: 'giphy-embed',
    }

    let code = isResponsive
        ? `<div style="width:100%;height:0;padding-bottom:${Math.round(ratio * 100)}%;position:relative;">`
        : ''
    code += `<iframe src="${params.src}" width="${params.width}" height="${params.height}" style="${params.style}" frameBorder="${params.frameBorder}" class="${params.class}" allowFullScreen></iframe>`
    code += isResponsive ? '</div>' : ''
    code += `<p><a href="${url}">via GIPHY</a></p>`

    return code
}

import { MouseEventHandler } from 'react'
import { Pill, TagText } from './style'

type TagPillsProps = {
    href?: string
    text: string
    className?: string
    onClick?: MouseEventHandler<HTMLAnchorElement>
}

const TagPills = ({ href, text, onClick, className }: TagPillsProps) => {
    return href ? (
        <Pill href={href} onClick={onClick} className={className}>
            <TagText>{text}</TagText>
        </Pill>
    ) : (
        <Pill as="div" className={className}>
            <TagText>{text}</TagText>
        </Pill>
    )
}

export default TagPills

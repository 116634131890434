'use client'
import styled from 'styled-components'
import ClientGrid from '../grid'
import HeaderInit from '../header-init'

const DownArrow = styled.div`
    margin: 15px 0 30px 0;
`

const GeoBlockedImg = styled.div`
    img {
        display: inline;
    }
    source {
        display: inline;
    }
`

const GeoBlocked = () => {
    return (
        <HeaderInit>
            <GeoBlockedImg className="text-giphyWhite flex-col text-center">
                <picture>
                    <source type="image/webp" srcSet="https://media.giphy.com/media/ys3xvXRwsdjoYvYR3h/giphy.webp" />
                    <img
                        src="https://media.giphy.com/media/ys3xvXRwsdjoYvYR3h/giphy.gif"
                        width="350"
                        alt="Content unavailable in your location"
                    />
                </picture>
                <h3>Oops! This content is not</h3>
                <h3>available in your current location.</h3>
                <DownArrow className="ss-icon ss-navigatedown" />
                <ClientGrid />
            </GeoBlockedImg>
        </HeaderInit>
    )
}
export default GeoBlocked

'use client'
import { giphyGreen } from '@giphy/colors'
import { IUser } from '@giphy/js-types'
import styled from 'styled-components'
import Avatar_ from './avatar'
import Username from './username'

const Avatar = styled(Avatar_)`
    object-fit: cover;
`

const Container = styled.div`
    margin-right: 6px;
    display: flex;
    gap: 12px;
    align-items: center;
`

const DisplayName = styled.h2`
    line-height: 16px;
    font-size: 16px;
    overflow: hidden;
    display: flex;
`
const Names = styled.div`
    overflow: hidden;
`

const ForHireText = styled.span`
    color: ${giphyGreen};
    display: block;
    flex-shrink: 0;
    font-size: 14px;
    font-weight: bold;
    line-height: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:before {
        content: '●';
        line-height: 18px;
        font-size: 18px;
        margin-right: 5px;
        position: relative;
        top: 1px;
    }
`

type Props = { user: IUser & { is_freelance?: boolean }; className?: string; canViewForHire?: boolean }

const Attribution = ({ user, className = '', canViewForHire = false }: Props) => {
    return (
        <Container className={className}>
            <Avatar user={user} size={50} />
            <Names>
                <DisplayName>
                    {canViewForHire && user?.is_freelance && !user.display_name ? user.username : user.display_name}
                </DisplayName>
                {canViewForHire && user?.is_freelance && <ForHireText>Available for Work</ForHireText>}
                {(!canViewForHire || !user?.is_freelance) && (
                    <Username user={user} className="text-giphyLightGrey hover:text-giphyWhite" />
                )}
            </Names>
        </Container>
    )
}

export default Attribution

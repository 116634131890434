'use client'
'use client'
import { IGif } from '@giphy/js-types'
import { ReactNode, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import styled from 'styled-components'
import { modalZIndex } from 'ui/src/constants'
import { CloseIcon } from 'ui/src/icons/navigation'
import FlagForm from './flag-form'

const Container = styled.div`
    position: fixed;
    inset: 0;
    background: rgba(0, 0, 0, 0.9);
    display: flex;
    flex-direction: column;
    padding: 20px 0;
    z-index: ${modalZIndex};
    overflow: auto;
`

const Close = styled.div`
    margin-left: 15px;
    margin-top: 5px;
`

type Props = { gif: IGif; children: ReactNode; className?: string; reportPlatform?: string }
function ReportButtonManager({ gif, children, className, reportPlatform }: Props) {
    const [showFlagForm, setShowFlagForm] = useState(false)
    const [scrollY, setScrollY] = useState(0)
    useEffect(() => {
        if (reportPlatform) {
            setShowFlagForm(true)
        }
    }, [reportPlatform])

    return (
        <>
            <div
                className={className}
                onClick={() => {
                    setShowFlagForm(true)
                }}
            >
                {children}
            </div>
            {showFlagForm &&
                createPortal(
                    <Container
                        className="bg-giphyDarkestGrey"
                        onScroll={(e) => {
                            setScrollY(e.currentTarget.scrollTop)
                        }}
                    >
                        <Close onClick={() => setShowFlagForm(false)}>
                            <CloseIcon height={20} />
                        </Close>
                        <FlagForm
                            scrollY={scrollY}
                            gif={gif.id as string}
                            toggleForm={() => setShowFlagForm(false)}
                            platform={reportPlatform || 'web'}
                        />
                    </Container>,
                    document.body,
                    `${gif.id}-flag`
                )}
        </>
    )
}

export default ReportButtonManager

import { SVGAttributes } from 'react'

export const NavigateRightIcon = (props: SVGAttributes<SVGElement>) => (
    <svg fill={props.color || 'white'} viewBox="0 0 12 20" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.00439 0C3.09224 0 3.18009 0.0390432 3.26794 0.117131L11.8946 9.69253C11.9649 9.77713 12 9.87799 12 9.99512C12 10.1122 11.9649 10.2131 11.8946 10.2977L3.26794 19.8731C3.19766 19.9577 3.10835 20 3 20C2.89165 20 2.80234 19.9577 2.73206 19.8731L0.105418 16.9546C0.0351392 16.8765 0 16.7773 0 16.6569C0 16.5365 0.0351392 16.4373 0.105418 16.3592L5.83309 9.99512L0.105418 3.63104C0.0351392 3.55295 0 3.45372 0 3.33333C0 3.21295 0.0351392 3.11371 0.105418 3.03563L2.73206 0.117131C2.81991 0.0390432 2.91069 0 3.00439 0Z"
        />
    </svg>
)

export default NavigateRightIcon

'use client'
import { EditContext } from '@/context/edit-manager'
import { IGif } from '@giphy/js-types'
import { useContext } from 'react'
type Props = { gif: IGif; className: string; children: React.ReactNode }
const OpenEditPanel = ({ gif, className, children }: Props) => {
    const { addToEdit } = useContext(EditContext)
    return (
        <div onClick={() => addToEdit(gif.id)} className={className}>
            {children}
        </div>
    )
}
export default OpenEditPanel

import { useGiphyFetch } from '@/app/util/use-gf'
import { IGif } from '@giphy/js-types'
import { useContext, useEffect, useRef, useState } from 'react'
import { GifsContext } from '../gifs-context'

export function useRelatedGifPagination(gif: IGif, limit: number = 4) {
    const currentId = gif.id
    const { relatedGifs, setRelatedGifs } = useContext(GifsContext)
    const [doneFetching, setIsDoneFetching] = useState(false)
    const isFetching = useRef(false)

    const gf = useGiphyFetch()
    const index = relatedGifs.findIndex((gif) => gif.id === currentId)
    const prev = relatedGifs[index - 1]
    const next = relatedGifs[index + 1]
    useEffect(() => {
        const fetchRelated = async () => {
            isFetching.current = true
            const nextGifs = relatedGifs.length === 0 ? [gif] : [...relatedGifs]
            // fetch related based on the first gif we land on
            const result = await gf.related(nextGifs[0].id as string, {
                offset: Math.max(0, relatedGifs.length - 1),
                limit,
            })
            result.data.forEach((gif) => {
                // add gifs if they don't exist
                if (!nextGifs.find((g) => g.id === gif.id)) {
                    nextGifs.push(gif)
                }
            })
            if (nextGifs.length !== relatedGifs.length) {
                setRelatedGifs(nextGifs)
            } else {
                // if we don't have any new gifs, we're done fetching
                setIsDoneFetching(true)
            }
            isFetching.current = false
        }
        if (!next && !doneFetching && !isFetching.current) {
            fetchRelated()
        }
    }, [next, relatedGifs, gf, currentId, gif, setRelatedGifs, limit, doneFetching])
    return { prev, next }
}

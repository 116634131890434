import { IGif } from '@giphy/js-types'
import styled from 'styled-components'
import { ShareUrlRenditions, ShareUrlRenditionType } from 'utils/src/media/sharing'

const TrayContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: auto;
    margin-top: 40px;
    gap: 10px;
    flex-wrap: wrap;
`

const RenditionSelector = styled.span<{ selected: boolean }>`
    font-size: 13px;
    cursor: pointer;
    opacity: 0.6;
    ${({ selected }) =>
        selected &&
        `
        opacity: 1.0;
        border-bottom: solid .8px white;
    `}
    span {
        font-weight: bold;
    }
`
type Props = {
    currentRendition: ShareUrlRenditionType
    setRenditionType: (whichRendition: ShareUrlRenditionType) => void
    showsMp4?: boolean
    gif: IGif
}

function convertToMb(sizeInBytes: number | string) {
    return (Number(sizeInBytes) / (1024 * 1024)).toFixed(2)
}

export const RenditionTray = ({ currentRendition, setRenditionType, gif, showsMp4 = false }: Props) => {
    const smallSize = gif.images[ShareUrlRenditions.Small].size
    const socialSize = gif.images[ShareUrlRenditions.Social].size
    const originalSize = gif.images[ShareUrlRenditions.Original].size
    const mp4Size = gif.images[ShareUrlRenditions.MP4].mp4_size

    return (
        <TrayContainer>
            {smallSize && (
                <RenditionSelector
                    onClick={() => setRenditionType(ShareUrlRenditions.Small)}
                    selected={currentRendition === ShareUrlRenditions.Small}
                >
                    <span>Small</span> {convertToMb(smallSize)}mb
                </RenditionSelector>
            )}
            {socialSize && (
                <RenditionSelector
                    onClick={() => setRenditionType(ShareUrlRenditions.Social)}
                    selected={currentRendition === ShareUrlRenditions.Social}
                >
                    <span>Social</span> {convertToMb(socialSize)}mb
                </RenditionSelector>
            )}
            {originalSize && (
                <RenditionSelector
                    onClick={() => setRenditionType(ShareUrlRenditions.Original)}
                    selected={currentRendition === ShareUrlRenditions.Original}
                >
                    <span>Original</span> {convertToMb(originalSize)}mb
                </RenditionSelector>
            )}
            {showsMp4 && mp4Size && (
                <RenditionSelector
                    onClick={() => setRenditionType(ShareUrlRenditions.MP4)}
                    selected={currentRendition === ShareUrlRenditions.MP4}
                >
                    <span>MP4</span> {convertToMb(mp4Size)}mb
                </RenditionSelector>
            )}
        </TrayContainer>
    )
}

import { SVGAttributes } from 'react'

export const NavigateLeftIcon = (props: SVGAttributes<SVGElement>) => (
    <svg fill={props.color || 'white'} viewBox="0 0 12 20" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.99561 0C8.90776 0 8.81991 0.0390432 8.73206 0.117131L0.105419 9.69253C0.035141 9.77713 9.53674e-07 9.87799 9.53674e-07 9.99512C9.53674e-07 10.1122 0.035141 10.2131 0.105419 10.2977L8.73206 19.8731C8.80234 19.9577 8.89165 20 9 20C9.10835 20 9.19766 19.9577 9.26794 19.8731L11.8946 16.9546C11.9649 16.8765 12 16.7773 12 16.6569C12 16.5365 11.9649 16.4373 11.8946 16.3592L6.16691 9.99512L11.8946 3.63104C11.9649 3.55295 12 3.45372 12 3.33333C12 3.21295 11.9649 3.11371 11.8946 3.03563L9.26794 0.117131C9.18009 0.0390432 9.08931 0 8.99561 0Z"
        />
    </svg>
)

export default NavigateLeftIcon

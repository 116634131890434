import { shareServices } from '@/util/asset-url'
import { IGif } from '@giphy/js-types'
import { getContentOfGif, getCreatorOfGif, setGADataLayer } from 'analytics'
import { facebook, pinterest, reddit, sms, twitter } from './util'

const ShareComponents = ({ gif }: { iconSize: number; gif: IGif; className?: string }) => {
    return (
        <div className="flex gap-1">
            {shareServices
                .filter((s) => s.id !== 'Instagram')
                .map((service) => (
                    <div
                        className="flex h-9 flex-1 content-center justify-center md:size-10 md:flex-none"
                        style={{ background: service.bg }}
                        key={service.id}
                        onClick={() => {
                            switch (service.id as (typeof shareServices)[number]['id']) {
                                case 'SMS':
                                    sms(gif.url)
                                    break
                                case 'Facebook':
                                    facebook(gif.url)
                                    break
                                case 'Twitter':
                                    twitter(gif.url)
                                    break
                                case 'Pinterest':
                                    pinterest(gif.images.original.url, gif.url)
                                    break
                                case 'Reddit':
                                    reddit(gif.url)
                                    break
                            }
                            setGADataLayer({
                                event: 'share_success',
                                options: {
                                    social: {
                                        socialChannel: service.id,
                                    },
                                    content: getContentOfGif(gif),
                                    creator: getCreatorOfGif(gif),
                                },
                            })
                        }}
                    >
                        <img src={service.icon} alt={service.id} />
                    </div>
                ))}
        </div>
    )
}
export default ShareComponents

import { giphyLocalUrl } from '@/app/util/env'
import { Cookies, GET } from '@/app/util/url'
import { appendParams, convertToPagination } from '@/util/url'
import { PaginationOptions, Result } from '@giphy/js-fetch-api'
import { IChannel, IGif } from '@giphy/js-types'
import { timeoutFetch } from 'utils/src/api/fetch'

interface CountryOptions {
    country?: string
}
export interface GenericResult<T> extends Result {
    data: T[]
}

export const BASE_PATHNAME = `/api/v4/`

export const channelsUrl = `${BASE_PATHNAME}channels/`

const getResult = async <T>(
    pathname: string,
    qs: { offset?: number | undefined; [key: string]: any } = {},
    cookie?: Cookies,
    timeout?: number
) => {
    const url = appendParams(new URL(`${BASE_PATHNAME}${pathname}`, giphyLocalUrl), qs)
    const f = await timeoutFetch({ url: url, options: GET(cookie), timeout: timeout })
    if (!f.ok) {
        console.error(`Failed to fetch ${url}`)
    }
    const result = await f.json()
    return convertToPagination(result, qs.offset || 0) as GenericResult<T>
}

export type ArtistChannelsOptions = {
    filter?: string
    freelance?: boolean
    order?: 'asc' | 'desc'
    sort?: 'updatetime' | 'username'
} & PaginationOptions

export const getArtists = async (options: ArtistChannelsOptions) => {
    return getResult<IChannel>(`artists`, options)
}

type ChannelClipsOptions = PaginationOptions & CountryOptions
export const getChannelClipsByUsername = async (username: string, options: ChannelClipsOptions, cookie?: Cookies) => {
    return getResult<IGif>(`channels/${username}/clips/feed`, options, cookie)
}

export const getFeaturedChannels = async () => {
    return getResult<{
        id: number
        slug: string
        url: string
        display_name: string
        avatar_url: string
    }>(`channels/featured-channels`)
}

export const getFeaturedTags = async (options: PaginationOptions = {}) => {
    return getResult<{
        channel_slug: string
        id: number
        name_encoded: string
        name: string
        is_featured: boolean
        is_nsfw: boolean
        is_hidden: boolean
        create_datetime: string
        update_datetime: string
        count: number
    }>(`channels/featured-tags`, options)
}

type ChannelGifsOptions = PaginationOptions & CountryOptions
export const getChannelGifsById = async (id: number, options: ChannelGifsOptions, cookie?: Cookies) => {
    return getResult<IGif>(`channels/${id}/feed`, options, cookie, 5000)
}

type ChannelOptions = { fetch_children?: boolean; fetch_ranked_tags?: boolean; slug?: string } & PaginationOptions &
    CountryOptions
export const getChannelByUsername = async (options: ChannelOptions = {}, cookie: Cookies) => {
    const url = appendParams(new URL(channelsUrl, giphyLocalUrl), options)
    const f = await timeoutFetch({ url: url, options: GET(cookie), timeout: 2500 })
    return (await f.json()) as IChannel
}

type ChannelSearchOptions = { tagged?: boolean; q?: string } & PaginationOptions
export const getChannelSearch = async (id: number, options: ChannelSearchOptions = {}, cookie: Cookies) => {
    return getResult<IGif>(`channels/${id}/search/`, options, cookie)
}

type ChannelChildren = { fetch_associated_channels?: boolean } & PaginationOptions & CountryOptions
export const getChannelChildren = async (id: number, cookie: Cookies, options: ChannelChildren = {}) => {
    return getResult<IChannel>(`channels/${id}/children`, options, cookie)
}

// export const fetchChannelByUsername = async (username: string) => {
//     const channel = await fetch(`${giphyLocalUrl}/api/v4/channels/?slug=${username}`)
//     return channel.json()
// }

// export const fetchChannelGifs = async (channelId: string) => {
//     const gifs = await fetch(`${giphyLocalUrl}/api/v4/channels/${channelId}/feed/`)
//     const result = convertToPagination<IGif>(await gifs.json(), 0)
//     return result.data
// }

export const fetchChannelClips = async (username: string, options?: PaginationOptions) => {
    const gifs = await fetch(
        appendParams(new URL(`${giphyLocalUrl}/api/v4/channels/${username}/clips/feed/`), options || {})
    )
    return convertToPagination<IGif>(await gifs.json(), options?.offset || 0)
}

export const getChannelById = async (id: number, cookie: Cookies) => {
    const url = new URL(`${channelsUrl}${id}`, giphyLocalUrl)
    const f = await timeoutFetch({ url: url, options: GET(cookie) })
    return (await f.json()) as IChannel
}

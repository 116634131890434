import styled from 'styled-components'

export const modalPadding = 20

export const Gif = styled.img`
    display: block;
    align-self: center;
    object-fit: contain;
`

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: ${modalPadding}px;
    flex: 1;
`

export const Footer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: end;
    gap: 4px;
    padding: ${modalPadding}px;
`

export const CopyWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2px;
    flex: 1;
`

import { ReactNode } from 'react'
import styled from 'styled-components'
import { modalZIndex } from 'ui/src/constants'
import { CloseIcon } from 'ui/src/icons/navigation'
const Container = styled.div`
    position: fixed;
    inset: 0;
    background: rgba(0, 0, 0, 0.9);
    display: flex;
    flex-direction: column;
    padding: 20px 0;
    z-index: ${modalZIndex};
    overflow: auto;
`

const Cancel = styled.div`
    font-weight: bold;
    align-self: center;
    display: flex;
`

const Close = styled.div`
    margin-left: 15px;
    margin-top: 5px;
`

const Modal = ({ children, onClose, className }: { className?: string; children: ReactNode; onClose: () => void }) => {
    return (
        <Container className={className}>
            <Close onClick={onClose}>
                <CloseIcon height={20} />
            </Close>
            {children}
            <Cancel onClick={onClose}>Cancel</Cancel>
        </Container>
    )
}
export default Modal
